import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgPeopleCover from "../../assets/images/mission/people/people-cover.png"
import imgPeopleOne from "../../assets/images/home/people-img-1.png"
import imgPeopleTwo from "../../assets/images/home/people-img-2.png"
import imgPeopleThree from "../../assets/images/home/people-img-3.png"

const PeoplePage = () => (
  <Layout>
    <SEO title="People" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgPeopleCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{position: "relative"}}>
          <div className="large-container pv6 ph2">
                <h2 className="uk-text-center mb5">
                    EMM TEAM
                </h2>
                <div className="custom-slider ph4-ns" data-uk-slider>

                  <div className="uk-position-relative">

                      <div className="uk-slider-container uk-light">
                          <ul className="uk-slider-items">
                              <li>
                                  <img src={imgPeopleOne} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleTwo} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleThree} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleOne} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleTwo} alt=""/>
                              </li>
                          </ul>
                      </div>

                      <div className="uk-hidden@s uk-light">
                      <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                          <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                      </div>

                      <div className="uk-visible@s">
                          <a className="uk-position-center-left-out uk-position-small" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                          <a className="uk-position-center-right-out uk-position-small" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                      </div>

                  </div>

                  <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default PeoplePage
